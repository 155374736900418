import React, {useEffect, useState} from 'react';
import './editResultsModal.scss'
import Api from "../../../API";
import {FormInput} from "../../FormInput/FormInput";
import {toast} from "react-toastify";
import {Error} from "../../ToastMeassage/Error";
import {Success} from "../../ToastMeassage/Success";

const EditResultsModal = ({ closeEditModal, result }) => {

  const [formData, setFormData] = useState({
    description: "",
    status: false
  });


  useEffect(() => {
    setFormData({
      description: result?.description ? result?.description : "",
      status: result?.status ? result?.status : false,
    })
  }, []);

  const handleChange = ({target}) => {
    if(target.type === 'checkbox'){
      setFormData({...formData, [target.name]: target.checked})
    } else setFormData({...formData, [target.name]: target.value})
  };

  const editResult = e => {
    e.preventDefault();
    Api.putData(`results/update/${result.id}/`, formData,)
      .then(() => {
        closeEditModal();
        toast(({closeToast}) => <Success message={"Edited successfully"}/>);
      })
      .catch(e => toast(({closeToast}) => <Error message={e.message}/>))
  };

  return (
    <div className="edit">
      <div className="edit__box">
        <p className="edit__title">Edit result</p>
        <form className="edit__form w-75" onChange={handleChange} onSubmit={editResult}>

          <FormInput
            name="description"
            type="text"
            multiline
            rows={4}
            label="Description"
            value={formData.description}
            required
          />

          <label htmlFor="status" className="d-block pb-2 mr-auto" style={{width: 90}}>
            <input type="checkbox" id="status" name="status" className="edit__form_checkbox" checked={formData.status}/>
            Publish
          </label>
          <div className="add__buttons">
            <button className="add__btn btn-white" style={{width: "150px"}} onClick={() => closeEditModal}>Cancel</button>
            <button className="add__btn btn-blue" style={{width: "150px"}} type="submit">Edit</button>
          </div>
        </form>
      </div>

      <div className="edit-bg" onClick={closeEditModal}>
      </div>
    </div>
  );
};

export default EditResultsModal;