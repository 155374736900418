import React, {useEffect, useState} from 'react';
import './addNewTestModal.scss'
import Api from "../../../API";
import {toast} from "react-toastify";
import {FormInput} from "../../FormInput/FormInput";
import {Success} from "../../ToastMeassage/Success";
import {CustomModal} from "../CustomModal/CustomModal";

const AddNewTestModal = ({ setAddNewTestModal, id, type, data, show, setTests }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: false
  });

  useEffect(() => {
    setFormData({
      title: data?.title ? data?.title : "",
      description: data?.description ? data?.description : "",
      status: data?.status ? data.status : false
    });
  },[data]);

  const handleChange = ({target}) => {
    if(target.type === 'checkbox'){
      setFormData({...formData, [target.name]: target.checked})
    } else setFormData({...formData, [target.name]: target.value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    type === 'test' ?
      Api.putData(`tests/update/${id}/`, formData)
        .then(() => {
          setAddNewTestModal(false);
        })
        .catch(e => toast(({closeToast}) => <span>{e.message}</span>))
      :
    Api.postData(`tests/create/`, formData)
      .then(() => {
        setAddNewTestModal(false);
        toast(({closeToast})=><Success message="Created!"/>);
        Api.getData("/tests").then(res => {
          setTests(res.data)
          setFormData({title: '', description: '', status: false})
        }).catch(e => console.error(e));
      })
      .catch(e => toast(({closeToast}) => <span>{e.message}</span>));
  }

  return (
    <CustomModal
      open={show}
      onClose={() => setAddNewTestModal(false)}
    >
      <form className="add__box" onChange={handleChange} onSubmit={handleSubmit}>
        <p className="add__title">{type === 'test' ? 'Edit test' : 'Add new test'}</p>
        <div className="add__form w-75 mb-1">
          <FormInput
            name="title"
            label="Name"
            required={true}
            value={formData.title}
          />
          <FormInput
            name="description"
            type="text"
            label="Description"
            required={true}
            multiline
            rows={3}
            value={formData.description}
          />
  
          <label htmlFor="status" className="add__form_label-checkbox py-2">
            <input value={formData.status} type="checkbox" id="status" name="status" className="add__form_checkbox" />
            Publish
          </label>
        </div>
        <div className="add__buttons">
          <button className="add__btn btn-white" onClick={() => setAddNewTestModal(false)}>Cancel</button>
          <button className="add__btn btn-blue" type="submit">Add</button>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddNewTestModal;