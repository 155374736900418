import React, { useEffect, useState } from "react";
import "./styles.scss";
import Api from "../../API";
import { Grid } from "@mui/material";
import { TableEmptyData } from "../../components/TableEmtyData/TableEmptyData";
import { useParams, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import * as XLSX from "xlsx";

const ResultInfo = () => {
    const { id } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]); // Array of results
    const [result, setResult] = useState(null); // Individual result
    const [excelData, setExcelData] = useState([]);
    const index = location.state?.index;

    useEffect(() => {
        Api.getData("results/")
            .then((res) => {
                setResults(res.data);
                if (
                    index != null &&
                    index < res.data.length &&
                    res.data[index]?.test_type === "emotion_analysis"
                ) {
                    setResult(res.data[index]);
                    readExcelFileIfNeeded(res.data[index]);
                } else {
                    getTest();
                }
            })
            .catch((e) => console.error(e));
    }, [index]);

    console.log(results);
    console.log(index);
    console.log(result);

    const readExcelFileIfNeeded = (data) => {
        if (data?.test_type === "emotion_analysis") {
            readExcelFile(data.file);
        }
    };

    const getTest = async () => {
        setLoading(true);
        try {
            const response = await Api.getData("results/" + id);
            setResult(response.data);
            console.log(result);
            readExcelFileIfNeeded(response.data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const readExcelFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames.find(
                (name) => name === "Emotion Averages"
            );
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            formatAndSetExcelData(sheetData);
        };
        fetch(file)
            .then((res) => res.arrayBuffer())
            .then((buffer) => reader.readAsArrayBuffer(new Blob([buffer])));
    };

    const formatAndSetExcelData = (data) => {
        const headers = data[0];
        const formattedData = data.slice(1).map((row, index) => {
            let rowData = { id: index + 1 };
            row.forEach((cell, i) => {
                rowData[headers[i]] = cell;
            });
            return rowData;
        });
        setExcelData(formattedData);
    };

    const findResult = (test) => {
        return result?.test_results_parameters?.find(
            (item) => item.parameter.title === test
        );
    };

    const getResult = (type, value) => {
        const res = findResult(type);
        if (res) {
            return Number.isInteger(res[value])
                ? res[value]
                : res[value].toFixed(3);
        }
    };

    const toFixedResult = (value) => {
        return Number.isInteger(value) ? value : value.toFixed(3);
    };

    console.log(excelData);

    if (loading) {
        return <Loader style={{ marginTop: 100 }} />;
    }

    if (result?.test_type === "emotion_analysis") {
        return (
            <>
                <h4 className="title">{result?.test?.title}</h4>
                <div className="table-container fade-in">
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="center"
                        className="mt-3"
                    >
                        <Grid
                            item
                            alignItems="start"
                            textAlign={"left"}
                            container
                            justifyContent="flex-start"
                            className="text-dark title-secondary mt-4 mb-2"
                        >
                            Result by Stimuli
                        </Grid>
                        <Grid container>
                            <Grid
                                item
                                container
                                alignItems="center"
                                className="fw-bold py-2 rounded-top"
                                style={{
                                    backgroundColor: "#F3F3F3",
                                    color: "#006674",
                                    border: "1px solid #CCCCCC",
                                }}
                            >
                                <Grid item xs={1}>
                                    #
                                </Grid>
                                <Grid item xs={2.58} className="text-start">
                                    {" "}
                                    Stimuli
                                </Grid>
                                <Grid item xs={1.2}>
                                    {" "}
                                    Neutral
                                </Grid>
                                <Grid item xs={1.2}>
                                    Happy
                                </Grid>
                                <Grid item xs={1.2}>
                                    Sad
                                </Grid>
                                <Grid item xs={1.2}>
                                    Angry
                                </Grid>
                                <Grid item xs={1.2}>
                                    Fearful
                                </Grid>
                                <Grid item xs={1.2}>
                                    Disgusted
                                </Grid>
                                <Grid item xs={1.2}>
                                    Surprised
                                </Grid>
                            </Grid>
                        </Grid>
                        {excelData.length ? (
                            excelData.map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    container
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    className="py-2 text-dark"
                                    style={{
                                        border: "1px solid #CCCCCC",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Grid item xs={1}>
                                        {item.id}
                                    </Grid>
                                    <Grid item xs={2.58} className="text-start">
                                        {" "}
                                        {/* Updated xs value for Stimuli */}
                                        {item.Stimulus}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {" "}
                                        {/* Updated xs values for equal spacing */}
                                        {toFixedResult(item.neutral)}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {toFixedResult(item.happy)}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {toFixedResult(item.sad)}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {toFixedResult(item.angry)}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {toFixedResult(item.fearful)}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {toFixedResult(item.disgusted)}
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        {toFixedResult(item.surprised)}
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <TableEmptyData />
                        )}
                        {/* <Grid
                            item
                            alignItems="start"
                            textAlign={"left"}
                            container
                            justifyContent="flex-start"
                            className="text-dark title-secondary mt-4 mb-2"
                        >
                            Results
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                            className="py-2 result-text fw-bold"
                            style={{
                                border: "1px solid #CCCCCC",
                                cursor: "pointer",
                            }}
                        >
                            <Grid item xs={1}>
                                {" "}
                            </Grid>
                            <Grid item xs={2} className="text-start">
                                {"Test Value"}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("FA1", "test_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("FA2", "test_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("COH", "test_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("TAR", "test_value")}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                            className="py-2 result-text fw-bold"
                            style={{
                                border: "1px solid #CCCCCC",
                                cursor: "pointer",
                            }}
                        >
                            <Grid item xs={1}>
                                {" "}
                            </Grid>
                            <Grid item xs={2} className="text-start">
                                {"Rest Value"}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("FA1", "rest_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("FA2", "rest_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("COH", "rest_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("TAR", "rest_value")}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                            className="py-2 result-text mb-5 fw-bold"
                            style={{
                                border: "1px solid #CCCCCC",
                                cursor: "pointer",
                            }}
                        >
                            <Grid item xs={1}>
                                {" "}
                            </Grid>
                            <Grid item xs={2} className="text-start">
                                {"Result"}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("FA1", "result_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("FA2", "result_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("COH", "result_value")}
                            </Grid>
                            <Grid item xs={2}>
                                {getResult("TAR", "result_value")}
                            </Grid>
                        </Grid> */}
                    </Grid>
                </div>
            </>
        );
    }

    return (
        <>
            <h4 className="title">{result?.test}</h4>

            <div className="table-container fade-in">
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    className="mt-3"
                >
                    <Grid
                        item
                        alignItems="start"
                        textAlign={"left"}
                        container
                        justifyContent="flex-start"
                        className="text-dark title-secondary mt-4 mb-2"
                    >
                        Stimuli
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            container
                            alignItems="center"
                            // justifyContent="center"
                            className="fw-bold py-2 rounded-top"
                            style={{
                                backgroundColor: "#F3F3F3",
                                color: "#006674",
                                border: "1px solid #CCCCCC",
                            }}
                        >
                            <Grid item xs={1}>
                                #
                            </Grid>
                            <Grid item xs={2} className="text-start">
                                Stimuli
                            </Grid>
                            <Grid item xs={2}>
                                FA1
                            </Grid>
                            <Grid item xs={2}>
                                FA2
                            </Grid>
                            <Grid item xs={2}>
                                COH
                            </Grid>
                            <Grid item xs={2}>
                                TAR
                            </Grid>
                        </Grid>
                    </Grid>
                    {result?.test_results_stimuli?.length ? (
                        result?.test_results_stimuli.map((item, index) => (
                            <Grid
                                key={index}
                                item
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                className="py-2 text-dark"
                                style={{
                                    border: "1px solid #CCCCCC",
                                    cursor: "pointer",
                                }}
                            >
                                <Grid item xs={1}>
                                    {index + 1}
                                </Grid>
                                <Grid item xs={2} className="text-start">
                                    {item?.title}
                                </Grid>
                                <Grid item xs={2}>
                                    {toFixedResult(item?.fa1_value)}
                                </Grid>
                                <Grid item xs={2}>
                                    {toFixedResult(item?.fa2_value)}
                                </Grid>
                                <Grid item xs={2}>
                                    {toFixedResult(item?.coh_value)}
                                </Grid>
                                <Grid item xs={2}>
                                    {toFixedResult(item?.tar_value)}
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <TableEmptyData />
                    )}
                    <Grid
                        item
                        alignItems="start"
                        textAlign={"left"}
                        container
                        justifyContent="flex-start"
                        className="text-dark title-secondary mt-4 mb-2"
                    >
                        Results
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        className="py-2 result-text fw-bold"
                        style={{
                            border: "1px solid #CCCCCC",
                            cursor: "pointer",
                        }}
                    >
                        <Grid item xs={1}>
                            {" "}
                        </Grid>
                        <Grid item xs={2} className="text-start">
                            {"Test Value"}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("FA1", "test_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("FA2", "test_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("COH", "test_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("TAR", "test_value")}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        className="py-2 result-text fw-bold"
                        style={{
                            border: "1px solid #CCCCCC",
                            cursor: "pointer",
                        }}
                    >
                        <Grid item xs={1}>
                            {" "}
                        </Grid>
                        <Grid item xs={2} className="text-start">
                            {"Rest Value"}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("FA1", "rest_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("FA2", "rest_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("COH", "rest_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("TAR", "rest_value")}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        className="py-2 result-text mb-5 fw-bold"
                        style={{
                            border: "1px solid #CCCCCC",
                            cursor: "pointer",
                        }}
                    >
                        <Grid item xs={1}>
                            {" "}
                        </Grid>
                        <Grid item xs={2} className="text-start">
                            {"Result"}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("FA1", "result_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("FA2", "result_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("COH", "result_value")}
                        </Grid>
                        <Grid item xs={2}>
                            {getResult("TAR", "result_value")}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default ResultInfo;
