import React from 'react';
import {Modal, Box} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export const CustomModal = ({open, onClose, children}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="text-dark"
    >
      <Box sx={style}>
        { children }
      </Box>
    </Modal>
  );
};