import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {PlusIcon} from "../../assets/images/SVG/PlusIcon";
import "../TestInfo/TestInfo.scss";
import Api from "../../API";
import {TickIcon} from "../../assets/images/SVG/TickIcon";
import {CrossIcon} from "../../assets/images/SVG/CrossIcon";
import AddNewTestModal from "../../components/Modals/AddNewTestModal";

const TestsList = () => {
  const history = useHistory();
  const [tests, setTests] = useState([]);
  const [addNewTestModal, setAddNewTestModal] = useState(false);

  useEffect(() => {
    Api.getData('tests/')
      .then(res => {
        setTests(res.data);
      })
      .catch(e => console.error(e));
  }, []);

  return (
    <Grid container alignItems="center" className="text-dark">
      <Grid item alignItems="center" justifyContent="flex-start" flexDirection="column" className="my-3">
        <Typography variant="h5" className="text-start py-3">
          Tests
        </Typography>
        <button className="stress__buttons_elem btn-blue" onClick={() => setAddNewTestModal(true)}>
          <PlusIcon/> Add new test
        </button>
      </Grid>

      <Grid item container alignItems="center" justifyContent="center" className="mt-3">
        <Grid container >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            className="fw-bold p-2 rounded-top"
            style={{backgroundColor: "#F3F3F3", color: "#006674", border: "1px solid #CCCCCC"}}>
            <Grid item xs={1}>#</Grid>
            <Grid item xs={3} className="text-start">Title</Grid>
            <Grid item xs={5} className="text-start">Description</Grid>
            <Grid item xs={3}>Published</Grid>
          </Grid>
        </Grid>
        {tests.map((test, index) => (
          <Grid
            key={index}
            item
            container
            className="p-2"
            style={{backgroundColor: "", border: "1px solid #CCCCCC", cursor: "pointer"}}
            onClick={() => history.push(`/test/${test.id}`)}
          >
            <Grid item xs={1}>{index + 1}</Grid>
            <Grid item xs={3} className="text-start">{test.title}</Grid>
            <Grid item xs={5} className="text-start">{test.description}</Grid>
            <Grid item xs={3}>{test.status ? <TickIcon/> : <CrossIcon/>}</Grid>
          </Grid>
        ))}
      </Grid>
      <AddNewTestModal setAddNewTestModal={setAddNewTestModal} show={addNewTestModal} setTests={setTests}/>
    </Grid>
  );
};

export default TestsList;