import React from 'react';
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ path, exact, component }) => {

  const isAuth = localStorage.getItem('neuroToken')

  if(!isAuth) {
    return <Redirect to='/login/' />
  }

  return <Route path={path} exact={exact} component={component} />
};

export default PrivateRoute;