import React from 'react';
import {ResponsivePie} from '@nivo/pie'
import './pieChart.scss'


const PieChart = ({data}) => {
  return (
    <div className="pie">
      <p className="pie__title">Age</p>
      <ResponsivePie
        data={data}
        margin={{top: -20, right: 10, bottom: 150, left: 10}}
        startAngle={-55}
        innerRadius={0.35}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{scheme: 'set1'}}
        borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{from: 'color'}}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{from: 'color', modifiers: [['brighter', '10']]}}
        isInteractive={true}

        // fill={[
        //   {
        //     match: {
        //       id: 'ruby'
        //     },
        //     id: 'dots'
        //   },
        //   {
        //     match: {
        //       id: 'css'
        //     },
        //   },
        //   {
        //     match: {
        //       id: 'go'
        //     },
        //     id: 'dots'
        //   },
        //   {
        //     match: {
        //       id: 'python'
        //     },
        //     id: 'dots'
        //   },
        //   {
        //     match: {
        //       id: 'scala'
        //     },
        //     id: 'lines'
        //   },
        //   {
        //     match: {
        //       id: 'lisp'
        //     },
        //     id: 'lines'
        //   },
        //   {
        //     match: {
        //       id: 'elixir'
        //     },
        //     id: 'lines'
        //   },
        //   {
        //     match: {
        //       id: 'javascript'
        //     },
        //     id: 'lines'
        //   }
        // ]}
        legends={[
          {
            anchor: 'bottom',
            direction: 'column',
            justify: false,
            translateX: -80,
            translateY: 150,
            itemsSpacing: 0.1,
            itemWidth: 65,
            itemHeight: 36,
            itemTextColor: '#9c9c9c',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#414141',
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};

export default PieChart;