import React, {useEffect, useState} from 'react';
import './header.scss'

const Header = () => {
  const [userData, setUserData] = useState({})

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('neuroToken')))
  }, []);

  return (
    <div className="header">
      <div className="ml-auto">
        <p className="header__nickname m-0">{userData?.user_email}</p>
      </div>
    </div>
  );
};

export default Header;