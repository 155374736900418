import React from 'react';

export const DownloadIcon = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4782 0.138611C13.3682 0.0227052 13.2173 -0.046875 13.0609 -0.046875H3.69855C1.97095 -0.046875 0.544922 1.37348 0.544922 3.1009V20.8052C0.544922 22.5328 1.97095 23.9532 3.69855 23.9532H16.4057C18.1333 23.9532 19.5594 22.5328 19.5594 20.8052V6.74727C19.5594 6.59657 19.4898 6.45173 19.3913 6.3415L13.4782 0.138611ZM13.6463 1.99952L17.6058 6.15602H15.0319C14.2667 6.15602 13.6463 5.54151 13.6463 4.77631V1.99952ZM16.4057 22.7937H3.69855C2.61457 22.7937 1.70435 21.8952 1.70435 20.8052V3.1009C1.70435 2.01691 2.60871 1.11255 3.69855 1.11255H12.4869V4.77631C12.4869 6.18495 13.6233 7.31544 15.0319 7.31544H18.3999V20.8052C18.3999 21.8952 17.4956 22.7937 16.4057 22.7937Z" fill="#006674"/>
    <path d="M14.8229 18.7939H5.28088C4.9621 18.7939 4.70117 19.0547 4.70117 19.3737C4.70117 19.6924 4.9621 19.9534 5.28088 19.9534H14.8287C15.1475 19.9534 15.4085 19.6924 15.4085 19.3737C15.4085 19.0547 15.1475 18.7939 14.8229 18.7939Z" fill="#006674"/>
    <path d="M9.62885 16.7124C9.73908 16.8283 9.88977 16.8979 10.052 16.8979C10.2144 16.8979 10.3651 16.8283 10.4752 16.7124L13.8723 13.066C14.0926 12.834 14.0752 12.463 13.8434 12.2486C13.6114 12.0283 13.2404 12.0455 13.026 12.2775L10.6317 14.8456V8.51526C10.6317 8.19629 10.3708 7.93555 10.052 7.93555C9.73322 7.93555 9.47229 8.19629 9.47229 8.51526V14.8456L7.08386 12.2775C6.86358 12.0457 6.49829 12.0283 6.26647 12.2486C6.03466 12.4689 6.01727 12.8342 6.23754 13.066L9.62885 16.7124Z" fill="#006674"/>
    </svg>
    
  );
};