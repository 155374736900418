import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import testImage from "./../../assets/images/testImage.png";
import arrow from "./../../assets/images/arrow.svg";
import "./testPassing.scss";
import Api from "../../API";

const TestPassing = () => {
    let { id } = useParams();

    const [step, setStep] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [pending, setPending] = useState(true);
    const [testData, setTestData] = useState({});
    const audioRef = useRef(null);

    useEffect(() => {
        if (audioRef.current) {
            const playAudio = async () => {
                try {
                    await audioRef.current.play();
                } catch (e) {
                    console.error("Error playing audio:", e);
                }
            };
            playAudio();
        }
    }, [activeIndex]); // Dependency array includes activeIndex to trigger effect when it changes

    useEffect(() => {
        Api.getData(`tests/${id}/`)
            .then((res) => setTestData(res.data))
            .finally(() => setPending(false))
            .catch((e) => console.error(e));
    }, [id, activeIndex]);

    const stepChange = (calculator) => {
        calculator === "minus" ? setStep(step - 1) : setStep(step + 1);
        calculator === "minus"
            ? setActiveIndex(step - 1)
            : setActiveIndex(step + 1);
    };

    console.log(testData);

    const drawTest = () => {
        console.log(`${testData?.stimulus[activeIndex]?.title} played!`);

        if (
            testData?.stimulus[activeIndex]?.category?.title?.toLowerCase() ===
            "image"
        ) {
            return (
                <img
                    src={
                        testData?.stimulus[activeIndex]?.file
                            ? testData?.stimulus[activeIndex]?.file
                            : "https://i.pinimg.com/originals/c9/22/68/c92268d92cf2dbf96e3195683d9e14fb.png"
                    }
                    alt="testImage"
                    className="passing__image"
                />
            );
        }
        if (
            testData?.stimulus[activeIndex]?.category?.title?.toLowerCase() ===
            "video"
        ) {
            if (testData?.stimulus[activeIndex]?.file) {
                return (
                    <video
                        width="720"
                        height="400"
                        autoPlay
                        style={{ borderRadius: 20 }}
                    >
                        <source
                            src={testData?.stimulus[activeIndex]?.file}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                );
            } else {
                return (
                    <img
                        src={
                            "https://i.pinimg.com/originals/c9/22/68/c92268d92cf2dbf96e3195683d9e14fb.png"
                        }
                        alt="testImage"
                        className="passing__image"
                    />
                );
            }
        }
        if (
            testData?.stimulus[activeIndex]?.category?.title?.toLowerCase() ===
            "audio"
        ) {
            if (testData?.stimulus[activeIndex]?.file) {
                return (
                    <div>
                        <img
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "center",
                                height: 400,
                            }}
                            src={testImage}
                            alt="Audio"
                        />
                        <audio
                            ref={audioRef}
                            // autoPlay
                            controlsList="nodownload"
                            controls
                            style={{ opacity: 0, visibility: "hidden" }}
                            key={activeIndex}
                        >
                            <source
                                src={testData?.stimulus[activeIndex]?.file}
                                type="audio/mpeg"
                            />
                        </audio>
                    </div>
                );
            } else {
                return (
                    <img
                        src={
                            "https://i.pinimg.com/originals/c9/22/68/c92268d92cf2dbf96e3195683d9e14fb.png"
                        }
                        alt="testImage"
                        className="passing__image"
                    />
                );
            }
        }
    };
    if (pending) return <div>...loading</div>;
    return (
        <div className="passing inner-container">
            <h1 className="title">{testData?.stimulus[activeIndex]?.title}</h1>

            <p className="passing__description">
                {testData?.stimulus[activeIndex]?.description}
            </p>

            <div className="passing__level">
                <span>
                    {step + 1} of {testData?.stimulus_count}
                </span>
                <span>{testData?.stimulus[activeIndex]?.duration}</span>
            </div>

            <div className="passing__content">
                <button
                    className={`passing__arrow${
                        step <= 0 ? "-inactive" : ""
                    } before`}
                    style={{ border: "none" }}
                    disabled={step <= 0}
                    onClick={() => {
                        if (step === 0) return;
                        else stepChange("minus");
                    }}
                >
                    <img src={arrow} alt="arrow" />
                </button>

                {drawTest()}

                <button
                    className={`passing__arrow${
                        step >= testData?.stimulus_count - 1 ? "-inactive" : ""
                    } next`}
                    style={{ border: "none" }}
                    disabled={step >= testData?.stimulus_count - 1}
                    onClick={() => {
                        if (step === testData?.stimulus_count) return;
                        else stepChange("plus");
                    }}
                >
                    <img src={arrow} alt="arrow" />
                </button>
            </div>
        </div>
    );
};

export default TestPassing;
