import React, { useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Api from "../../API";

import "./../Registration/login.scss";
import { FormInput } from "../../components/FormInput/FormInput";

const initialValue = {
    email: "",
    password: "",
};

const RegisterSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("This field is required!"),
});

const Login = () => {
    const history = useHistory();
    const [serverMessage, setServerMessage] = useState(null);

    const isAuth = localStorage.getItem("neuroToken");

    const onSubmit = (data) => {
        console.log("Data being sent to auth/admins/token/:", data); // Log the data before sending

        Api.postData("auth/admins/token/", data)
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem(
                        "neuroToken",
                        JSON.stringify(res.data)
                    );
                    history.push("/home");
                }
            })
            .catch((e) => {
                setServerMessage(e?.response);
            });
    };

    if (isAuth) {
        return <Redirect to="/home" />;
    }

    return (
        <div className="login">
            <div className="login-form text-white px-3">
                <p className="login-form-submit__title">Log in</p>

                {/* {serverMessage &&
          <p className="text-warning">
            {serverMessage?.data?.detail || serverMessage?.data?.non_field_errors[0]}
          </p>
        } */}

                <Formik
                    initialValues={initialValue}
                    validationSchema={RegisterSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, handleChange }) => (
                        <Form style={{ width: "425px" }}>
                            <FormInput
                                label={
                                    <span className="text-white">Email*</span>
                                }
                                type="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                color="warning"
                                InputProps={{
                                    style: {
                                        color: "white",
                                    },
                                }}
                                error={errors.email && touched.email}
                                helperText={errors.email}
                            />
                            <FormInput
                                label={
                                    <span className="text-white">
                                        Password*
                                    </span>
                                }
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                color="warning"
                                InputProps={{
                                    style: {
                                        color: "white",
                                    },
                                }}
                                error={errors.password && touched.password}
                                helperText={errors.password}
                            />

                            <div className="login-form-submit-socials">
                                <a
                                    href="/"
                                    className="login-form-submit-socials__forgot"
                                >
                                    Forgot Password?
                                </a>
                            </div>

                            <div className="login-form-submit-logIn">
                                <span className="me-1">New here?</span>
                                <Link to="/registration"> Register</Link>

                                <button
                                    type="submit"
                                    className="login-form-submit-logIn__btn"
                                    style={{ marginLeft: "58px" }}
                                >
                                    Log in
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Login;
