import React, {useState, useEffect, useMemo} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useHistory, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import DeleteModal from '../../components/Modals/DeleteModal';
import DeleteTestModal from "../../components/Modals/DeleteTestModal";
import UploadStimulusModal from "../../components/Modals/UploadStimulusModal";
import AddNewTestModal from "../../components/Modals/AddNewTestModal";
import dots from './../../assets/images/dots.svg'
import './TestInfo.scss';
import Api from "../../API";
import {toast} from "react-toastify";
import {Error} from "../../components/ToastMeassage/Error";
import {Button, Grid, IconButton, Typography} from "@mui/material";
import {Vector} from "../../assets/images/SVG/vector";
import {PlusIcon} from "../../assets/images/SVG/PlusIcon";
import {PenIcon} from "../../assets/images/SVG/PenIcon";
import {CrossIcon} from "../../assets/images/SVG/CrossIcon";
import {TableEmptyData} from "../../components/TableEmtyData/TableEmptyData";


const TestInfo = () => {
  let {id} = useParams();
  const history = useHistory();

  const [deleteTestStatus, setDeleteTestStatus] = useState(false)
  const [deleteTestId, setDeleteTestId] = useState();

  const [modalStatus, setModalStatus] = useState(false);

  const [deleteStimulusId, setDeleteStimulusId] = useState();
  const [deleteStatus, setDeleteStatus] = useState(false);

  const [editModalStatus, setEditModalStatus] = useState(false)
  const [editResultId, setEditResultId] = useState();
  const [editStimul, setEditStimul] = useState(null);


  const [stimulus, setStimulus] = useState({});
  const [content, setContent] = useState([]);

  const [categories, setCategories] = useState([]);

  const [typeOfAction, setTypeOfAction] = useState('')


  const userData = JSON.parse(localStorage.getItem('neuroToken'));

  useEffect(() => {
    Api.getData(`tests/${id}/`)
      .then(res => {
        setStimulus(res.data);
        setContent(res.data.stimulus);
      })
      .catch(e => toast(({closeToast})=><Error message={e.message}/>));

    Api.getData('categories/')
      .then(res => setCategories(res.data))
      .catch(e => toast(({closeToast})=><Error message={e.message}/>));

  }, [id, modalStatus, deleteStatus, editModalStatus])


  const changeModalStatus = () => {
    setModalStatus(!modalStatus);
    setEditStimul(null);
  };

  const changeStimulus = (items) => {
    Api.putData(`tests/update/${id}/`, {
        stimulus: items.map((item) => {
          delete item.file
          return {
            ...item, category: item.category.id
          }
        })
      }
      , userData?.access)
      .catch(e => console.error(e))
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(content)
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem)

    changeStimulus(items)
    setContent(items)
  }

  const handleDeleteButtonClick = (id) => {
    setDeleteStatus(!modalStatus);
    setDeleteStimulusId(id)
  }
  const closeDeleteModal = () => {
    setDeleteStatus(false)
    setDeleteStimulusId(null)
  }

  const handleDeleteTestButtonClick = (id) => {
    setDeleteTestStatus(!modalStatus);
    setDeleteTestId(id)
  }
  const closeDeleteTestModal = () => {
    setDeleteTestStatus(false)
    setDeleteTestId(null)
  }

  const handleEditButtonClick = (id, type, item) => {
    setModalStatus(!modalStatus);
    setEditResultId(id);
    setTypeOfAction(type);
    setEditStimul(item);
  }
  const closeEditModal = () => {
    setModalStatus(false);
    setEditResultId(null);
    setTypeOfAction(null);
    setEditStimul(null);
  };

  const contentList = useMemo(() => (
    content?.map((item, index) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided) => (
          <div className="stress__list_elem" {...provided.draggableProps} {...provided.dragHandleProps}
               ref={provided.innerRef}>
            <Grid item xs={1}>
              <img className="stress__list_dots" src={dots} alt="dots"/>
              {index + 1}
            </Grid>
            <Grid item xs={2} className="text-start">{item?.title}</Grid>
            <Grid item xs={3} className="text-start">{item?.description}</Grid>
            <Grid item xs={2} className="text-start">{item?.category?.title}</Grid>
            <Grid item xs={2} className="text-end">{item?.duration}</Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleEditButtonClick(item.id, 'test', item)}> <PenIcon/> </IconButton>
              <IconButton onClick={() => handleDeleteButtonClick(item.id)}> <CrossIcon/> </IconButton>
            </Grid>

          </div>
        )}
      </Draggable>
    ))
  ), [content])


  return (
    <div className="stress">

      <Grid container alignItems="center" justifyContent="start">
        <h4 className="text-dark ml-auto">
          {stimulus?.title}
        </h4>
      </Grid>



  <div className='table-wrap'>
     <Grid container alignItems="center" justifyContent="space-between" className="py-3">
        <Grid item xs={5} className="text-start">
             <Link disabled={!content.length} to={`/passing/${id}`} className="stress__buttons_elem btn-blue" style={{color: "#FFFFFF"}}>
            <Vector/> Show test
          </Link>


          <button disabled={!content.length} className="stress__buttons_elem btn-white mt-3" onClick={() => history.push(`/edit/${id}`)}>
            Calculation
          </button>
        </Grid>
        <Grid item xs={4} className="text-end">
          <button
            className="btn-white stress__buttons_elem"
            onClick={() => setEditModalStatus(true)}>
            Edit
          </button>
          <button
            className=" danger stress__buttons_elem"
            style={{borderRadius: "10px"}}
            onClick={() => handleDeleteTestButtonClick(id)}
          >
            Delete
          </button>
        </Grid>
      </Grid>
  <Grid container alignItems="center" justifyContent="space-between" style={{backgroundColor: "#006674"}} className="p-2 rounded-top">
        <Grid item>
          <Typography variant="h6">
            Test Stimulus
          </Typography>
        </Grid>
        <Grid item>
          <button className="stress__buttons_elem btn-blue" onClick={changeModalStatus}>
            <PlusIcon/> Add stimulus
          </button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{backgroundColor: "#F3F3F3", color: "#006674"}} className="py-3 m-0 fw-bold">
        <Grid item xs={1}>#</Grid>
        <Grid item xs={2} className="text-start" >Title</Grid>
        <Grid item xs={3} className="text-start">Description</Grid>
        <Grid item xs={2} className="text-start">Category</Grid>
        <Grid item xs={2} className="text-end">Duration(sec)</Grid>
        <Grid item xs={2}>Action</Grid>
      </Grid>

      {content.length ?
        <div className="stress__list">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="data">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {contentList}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div> : <TableEmptyData/>
      }
  </div>



      {/*Change TEST*/} {/*MODALS*/}


      <UploadStimulusModal
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        changeModalStatus={changeModalStatus}
        title={stimulus?.title}
        categories={categories}
        id={id}
        type={typeOfAction}
        testId={editResultId}
        editStimulus={editStimul}
        closeEditModal={closeEditModal}
      />
      <DeleteModal open={deleteStatus} id={deleteStimulusId} closeDeleteModal={closeDeleteModal} />
      <DeleteTestModal open={deleteTestStatus} id={deleteTestId} closeDeleteTestModal={closeDeleteTestModal} />
      <AddNewTestModal setAddNewTestModal={setEditModalStatus} id={id} type="test" data={stimulus} show={editModalStatus}/>

    </div>
  );
};

export default TestInfo;
