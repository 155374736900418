import React from 'react';
import close from '../../../assets/images/close.svg'
import './showResults.scss'

const ShowResultsModal = ({ closeShowModal, data }) => {
  return (
    <div className="show">

      <div className="show__box">
        <div className="show__box_top">
          <p className="show__title">{data?.title}</p>
          <img
            width={16}
            height={16}
            src={close}
            alt="close"
            style={{cursor: 'pointer'}}
            onClick={() => closeShowModal()}
          />
        </div>
        <p className="show__text">
          {data?.description}
        </p>
        <p className="show__percent">89%</p>
        <div className="show__bar">
          <div
            className="show__bar_elem"
            style={{
              background: 'linear-gradient(270deg, #009FB6 0%, rgba(27, 189, 212, 0.42) 100%)',
              boxShadow: '5px 0px 10px rgba(7, 127, 145, 0.2)',
              borderRadius: 5,
              height: 67,
              width: '89%'
            }}
          >
          </div>
        </div>

        <div className="show__effected">
          <p className="show__effected_text">Less Affected</p>
          <p className="show__effected_text">Highly affected</p>
        </div>
      </div>

      <div className="show-bg" onClick={() => closeShowModal()}>
      </div>
    </div>
  );
};

export default ShowResultsModal;