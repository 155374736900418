import React from 'react';
import './deleteModal.scss'
import Api from "../../../API";
import {CustomModal} from "../CustomModal/CustomModal";

const DeleteModal = ({open, closeDeleteModal, id, type}) => {
  const userData = JSON.parse(localStorage.getItem('neuroToken'));

  const deleteStimulus = () => {
    type === 'result' ?
      Api.deleteData(`results/delete/${id}/`, userData?.access)
        .then(() => closeDeleteModal())
        .catch(e => console.error(e))
      :
    Api.deleteData(`stimulus/delete/${id}/`, userData?.access)
      .then(() => closeDeleteModal())
      .catch(e => console.error(e))
  }


  return (
    <CustomModal
      open={open}
      onClose={closeDeleteModal}
    >
      <div className="delete__box">
        <p className="delete__title">Are you sure you want to delete?</p>
        <div className="delete__buttons">
          <button className="delete__btn delete-blue" onClick={closeDeleteModal}>No</button>
          <button className="delete__btn danger" onClick={deleteStimulus}>Yes</button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteModal;