import React from 'react';
import Select from 'react-select'

import './profile.scss'

const Profile = () => {

  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
    {value: 'vanilla', label: 'Vanilla'},
    {value: 'vanilla', label: 'Vanilla'},
    {value: 'vanilla', label: 'Vanilla'},
    {value: 'vanilla', label: 'Vanilla'},

  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#fff' : state.isSelected ? '#fff' : '#006674',
      width: '100%',
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
      border: '1px solid #ccc',
      borderRadius: "15px",
      paddingLeft: "32px",
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      height: '42px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {...provided, opacity, transition};
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className="profile container">
      <form className="profile__form" action="submit">
        <div className="profile__form-inner">
          <div className="profile__form-left">
            <div className="profile__form-inputs">
              <label className="profile__form-label" htmlFor="name">Name</label>
              <input className="profile__form-input" type="text" id="name"/>
            </div>
            <div className="profile__form-inputs">
              <label className="profile__form-label" htmlFor="last-name">Last name</label>
              <input className="profile__form-input" type="text" id="last-name"/>
            </div>
            <div className="profile__form-inputs">
              <label className="profile__form-label">Gender</label>
              <Select
                maxMenuHeight={200}
                options={options}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 8,
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(0, 102, 116, 0.5)',
                    primary: '#006674',
                    primary50: 'none'
                  },
                })}
              />
            </div>
            <div className="profile__form-inputs">
              <label className="profile__form-label" htmlFor="age">Age</label>
              <input className="profile__form-input" type="number" id="age"/>
            </div>
          </div>
          <div className="profile__form-right">
            <div className="profile__form-inputs">
              <label className="profile__form-label" htmlFor="email">Email</label>
              <input className="profile__form-input" type="email" id="email"/>
            </div>
            <div className="profile__form-inputs">
              <label className="profile__form-label" htmlFor="phone">Phone</label>
              <input className="profile__form-input" type="tel" id="phone"/>
            </div>
            <div className="profile__form-inputs">
              <label className="profile__form-label">Country</label>
              <Select
                maxMenuHeight={200}
                options={options}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 8,
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(0, 102, 116, 0.5)',
                    primary: '#006674',
                    primary50: 'none'
                  },
                })}
              />
            </div>
            <div className="profile__form-inputs">
              <label className="profile__form-label">City</label>
              <Select
                maxMenuHeight={200}
                options={options}
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 8,
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(0, 102, 116, 0.5)',
                    primary: '#006674',
                    primary50: 'none'
                  },
                })}
              />
            </div>
          </div>
        </div>
        <button
          type='submit'
          className="profile__form-btn"
          onSubmit={handleSubmit}
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default Profile;