import React from 'react'
import {Route, Switch, Redirect, BrowserRouter} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Index from "./pages/Main";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Profile from "./pages/Profile";
import TestInfo from './pages/TestInfo';
import Results from './pages/Results';
import TestPassing from './pages/TestPassing';
import PrivateRoute from "./routes/PrivateRoute";
import Calculation from "./pages/Calculation/Calculation";
import Layout from "./components/Layout/Layout";
import {ToastContainer} from "react-toastify";
import Page404 from "./pages/Page404/Page404";
import TestsList from "./pages/TestsList/TestsList";
import ResultInfo from './pages/ResultTestInfo';

function App() {

  const router = useLocation();
  const link = router.pathname;

  if (link === '') {
    return <Redirect to={'/home'} />
  }

  return (
      <BrowserRouter>
        <Layout>
          <ToastContainer/>
          <Switch>
            <Route exact path="/"><Redirect to="/home" /></Route>
            <Route path="/login/" exact component={Login} />
            <Route path="/registration" exact component={Registration} />
            <PrivateRoute path="/home" exact component={Index} />
            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute path="/test/:id" exact component={TestInfo}/>
            <PrivateRoute path="/tests-list" exact component={TestsList}/>
            <PrivateRoute path="/passing/:id" exact component={TestPassing} />
            <PrivateRoute path="/results" exact component={Results} />
            <PrivateRoute path="/result/:id" exact component={ResultInfo} />
            <PrivateRoute path="/edit/:id" exact component={Calculation} />
            <Route path="*" component={Page404} />
          </Switch>
        </Layout>
      </BrowserRouter>
  );
}

export default App;
