import React from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';

export const CalendarChart = ({data}) => {
  return (
    <ResponsiveCalendar
      data={data}
      from="2019-03-01"
      to={new Date()}
      emptyColor="#eeeeee"
      colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
      margin={{ top: 0, right: 0, bottom: 0, left: 40 }}
      yearSpacing={40}
      monthBorderColor="#ffffff"
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      isInteractive={true}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: 'right-to-left'
        }
      ]}
    />
  );
};