import React from 'react';
import Header from '../Header';
import Dashboard from '../Dashboard';

const Layout = ({children}) => {
  return (
    <div className="w-100 h-100">
      <div className="w-100 h-100">
        <Header/>
        <Dashboard/>
      </div>
      <div style={{marginLeft: 290, position: 'relative'}} id="parentContainer">
        <div style={{overflowX: 'auto'}} className="p-3 text-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;