import React from 'react';

export const Error404 = () => {
  return (
    <>
      <svg width="386" height="152" viewBox="0 0 386 152" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M268.078 118.837V91.2751L331.344 1H369.583V89.2779H386V118.837H369.583V144.801H335.348V118.837H268.078ZM337.75 38.5481L304.115 89.2779H337.75V38.5481Z" fill="#1BBDD4"/>
        <path d="M0 118.837V91.2751L63.2654 1H101.505V89.2779H117.922V118.837H101.505V144.801H67.2696V118.837H0ZM69.6721 38.5481L36.0373 89.2779H69.6721V38.5481Z" fill="#1BBDD4"/>
        <g filter="url(#filter0_d_906_22417)">
          <path d="M142.906 98.2421C143.938 114.501 155.286 146.585 192.427 144.857C207.592 146.194 239.038 138.744 243.495 98.2421V95.155C246.466 95.8959 247.622 94.2289 247.828 93.3028C248.137 92.1709 248.125 88.5987 247.828 86.0374C247.506 83.259 244.63 81.8807 243.495 80.9546C243.495 26.3913 202.64 11.2392 202.64 9.69565C202.64 8.46083 196.657 3.46964 193.665 1C188.713 2.97571 184.38 8.51183 182.832 11.1873C154.358 22.0537 144.351 60.0032 142.906 78.6284C141.152 80.3777 137.929 83.0938 137.941 86.9635V92.5202C138.045 93.6521 139.179 95.6072 142.906 94.2289V98.2421Z" fill="#FFF7EA"/>
          <path d="M142.906 78.6284C149.344 36.1507 172.206 20.8601 182.832 17.3614M142.906 78.6284C144.351 60.0032 154.358 22.0537 182.832 11.1873M142.906 78.6284C141.152 80.3777 137.929 83.0938 137.941 86.9635V92.5202C138.045 93.6521 139.179 95.6072 142.906 94.2289M142.906 78.6284V94.2289M243.495 80.9546C241.431 64.2846 227.955 27.4348 202.64 17.3614M243.495 80.9546C243.495 26.3913 202.64 11.2392 202.64 9.69565M243.495 80.9546C244.63 81.8807 247.506 83.259 247.828 86.0374C248.125 88.5987 248.137 92.1709 247.828 93.3028C247.622 94.2289 246.466 95.8959 243.495 95.155M243.495 80.9546V95.155M202.64 17.3614C200.268 16.7439 195.15 15.5091 193.665 15.5091C191.808 15.5091 186.546 16.4352 182.832 17.3614M202.64 17.3614C202.64 17.3614 202.64 12.6893 202.64 9.69565M182.832 17.3614V11.1873M182.832 11.1873C184.38 8.51182 188.713 2.97571 193.665 0.999997C196.657 3.46964 202.64 8.46083 202.64 9.69565M243.495 95.155V98.2421C239.038 138.744 207.592 146.194 192.427 144.857C155.286 146.585 143.938 114.501 142.906 98.2421V94.2289" stroke="#1BBDD4" strokeWidth="0.717391" strokeLinecap="round"/>
        </g>
        <path d="M172.433 105.388C173.226 106.179 174.509 106.179 175.302 105.388L178.728 101.97C180.196 102.826 181.907 103.171 183.593 102.952C185.279 102.733 186.845 101.961 188.043 100.758L193.511 95.2833L191.486 93.2631L196.124 88.6368L193.268 85.7883L188.631 90.4146L187.436 89.2227L192.073 84.5964L189.218 81.7479L184.581 86.3742L182.555 84.354L177.067 89.8085C175.871 91.0099 175.104 92.5706 174.884 94.2499C174.664 95.9293 175.005 97.6341 175.852 99.1014L172.433 102.512C171.638 103.306 171.638 104.594 172.433 105.388ZM179.943 92.6772L182.555 90.0509L187.8 95.2833L185.168 97.8893C184.472 98.5832 183.529 98.973 182.545 98.973C181.562 98.973 180.618 98.5832 179.923 97.8893C179.227 97.1955 178.837 96.2544 178.837 95.2732C178.837 94.2919 179.227 93.3509 179.923 92.657L179.943 92.6772ZM206.634 68.3944C205.163 67.5487 203.454 67.2092 201.771 67.4283C200.087 67.6473 198.523 68.4128 197.318 69.6065L191.851 75.0813L202.806 86.0105L208.294 80.5358C209.491 79.3345 210.258 77.7738 210.478 76.0944C210.697 74.415 210.357 72.7102 209.509 71.2429L212.928 67.8322C213.723 67.0395 213.721 65.7516 212.923 64.9617C212.133 64.1784 210.858 64.1807 210.07 64.9667L206.634 68.3944ZM205.419 77.6873L202.806 80.3136L197.561 75.0813L200.174 72.4752C200.869 71.7813 201.813 71.3915 202.796 71.3915C203.78 71.3915 204.723 71.7813 205.419 72.4752C206.114 73.169 206.505 74.1101 206.505 75.0914C206.505 76.0726 206.114 77.0137 205.419 77.7075V77.6873Z" fill="#1BBDD4"/>
        <defs>
          <filter id="filter0_d_906_22417" x="134.712" y="0.587891" width="116.571" height="150.51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2.86957"/>
            <feGaussianBlur stdDeviation="1.43478"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_906_22417"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_906_22417" result="shape"/>
          </filter>
        </defs>
      </svg>
    </>
  );
};