import React, { useEffect, useState } from "react";
import DeleteModal from "../../components/Modals/DeleteModal";
import ShowResultsModal from "../../components/Modals/ShowResultsModal";
import EditResultsModal from "../../components/Modals/EditResultsModal";
import "./results.scss";
import Api from "../../API";
import { Grid, IconButton } from "@mui/material";
import { TickIcon } from "../../assets/images/SVG/TickIcon";
import { CrossIcon } from "../../assets/images/SVG/CrossIcon";
import { PenIcon } from "../../assets/images/SVG/PenIcon";
import { ResultIcon } from "../../assets/images/SVG/ResultIcon";
import { DownloadIcon } from "../../assets/images/SVG/DownloadIcon";
import { TableEmptyData } from "../../components/TableEmtyData/TableEmptyData";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";
import async from "async";

const truncateTitle = (title) => {
    if (title?.length > 8) {
        title = title.substring(0, 8) + "...";
    }
    return title;
};

const Results = () => {
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem("neuroToken"));

    const [deleteStatus, setDeleteStatus] = useState(false);
    const [deleteResultId, setDeleteResultId] = useState();

    const [showResults, setShowResultsModal] = useState(false);
    const [showResultsData, setShowResultsData] = useState();

    const [editStatus, setEditStatus] = useState(false);
    const [editResult, setEditResult] = useState(null);

    const [results, setResults] = useState([]);

    useEffect(() => {
        Api.getData("results/")
            .then((res) => {
                setResults(res.data);
            })
            .catch((e) => console.error(e));
    }, [deleteStatus, editStatus]);

    const handleDeleteButtonClick = (id) => {
        setDeleteStatus(!deleteStatus);
        setDeleteResultId(id);
    };
    const closeDeleteModal = () => {
        setDeleteStatus(false);
        setDeleteResultId(null);
    };

    const closeShowModal = () => {
        setShowResultsModal(false);
        setShowResultsData(null);
    };

    const handleEditButtonClick = (result) => {
        setEditStatus(!editStatus);
        setEditResult(result);
    };
    const closeEditModal = () => {
        setEditStatus(false);
        setEditResult(null);
    };

    /// test

    const headers = {
        "Content-Type": "blob",
        Authorization: userData?.access ? `JWT ${userData?.access}` : "",
    };

    const download1 = async (id) => {
        const config = {
            method: "GET",
            url: `https://neuroproject.synoserver.com/api/results/export/${id}/`,
            responseType: "arraybuffer",
            headers,
        };

        try {
            console.log("hi");
            let response = await axios(config);
            console.log(response);
            const outputFilename = `${Date.now()}.xlsx`;

            const url = URL.createObjectURL(
                new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.warn("Something went wrong ... ");
            throw Error(error);
        }
    };

    const download2 = async (id) => {
        const config = {
            method: "GET",
            url: `https://neuroproject.synoserver.com/api/results/parameters/export/${id}/`,
            responseType: "arraybuffer",
            headers,
        };

        try {
            const response = await axios(config);

            const outputFilename = `${Date.now()}.xlsx`;

            const url = URL.createObjectURL(
                new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.warn("Something went wrong ... ");
            throw Error(error);
        }
    };

    const download = async (item) => {
        if (item.test_type === "emotion_analysis") {
            try {
                const response = await axios.get(item.file, {
                    responseType: "blob",
                });
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;

                // Extract filename from content-disposition header or use a default
                const contentDisposition =
                    response.headers["content-disposition"];
                let filename = "download.xlsx";
                if (contentDisposition) {
                    const fileNameMatch =
                        contentDisposition.match(/filename="?(.+)"?/);
                    if (fileNameMatch && fileNameMatch.length === 2) {
                        filename = fileNameMatch[1];
                    }
                }

                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file: ", error);
                toast.warn("Something went wrong with the file download.");
            }
        } else {
            // Existing logic for other test types
            await download1(item.id);
            await download2(item.id);
        }
    };

    return (
        <>
            <h4 className="title">Test results</h4>

            <div className="table-container">
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    className="mt-3"
                >
                    <Grid container>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                            className="fw-bold py-2 rounded-top"
                            style={{
                                backgroundColor: "#F3F3F3",
                                color: "#006674",
                                border: "1px solid #CCCCCC",
                            }}
                        >
                            <Grid item xs={1}>
                                #
                            </Grid>
                            <Grid item xs={2} className="text-start">
                                Full Name
                            </Grid>
                            <Grid item xs={1}>
                                Age
                            </Grid>
                            <Grid item xs={1}>
                                Gender
                            </Grid>
                            <Grid item xs={1}>
                                City,Country
                            </Grid>
                            <Grid item xs={1}>
                                Test
                            </Grid>
                            <Grid item xs={1}>
                                Date
                            </Grid>
                            <Grid item xs={1}>
                                Data
                            </Grid>
                            <Grid item xs={1}>
                                Published
                            </Grid>
                            <Grid item xs={2}>
                                Action
                            </Grid>
                        </Grid>
                    </Grid>
                    {results?.length ? (
                        results.map((item, index) => (
                            <Grid
                                key={index}
                                item
                                container
                                alignItems="center"
                                className="py-2 text-dark"
                                style={{
                                    border: "1px solid #CCCCCC",
                                    cursor: "pointer",
                                }}
                            >
                                <Grid item xs={1}>
                                    {index + 1}
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className="text-start"
                                >{`${item?.user?.user_profile?.first_name} ${item?.user?.user_profile?.last_name}`}</Grid>
                                <Grid item xs={1}>
                                    {item?.user?.user_profile?.age}
                                </Grid>
                                <Grid item xs={1}>
                                    {item?.user?.user_profile?.gender?.name}
                                </Grid>
                                <Grid item xs={1}>
                                    {truncateTitle(
                                        `${item?.user?.user_profile?.country?.name}, ${item?.user?.user_profile?.city?.name}`
                                    )}
                                </Grid>
                                <Grid item xs={1}>
                                    {truncateTitle(item?.test?.title)}
                                </Grid>
                                <Grid item xs={1}>
                                    {item?.date}
                                </Grid>
                                <Grid
                                    className="d-flex justify-content-center"
                                    item
                                    xs={1}
                                >
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            download(item);
                                        }}
                                        href="#"
                                    >
                                        <DownloadIcon />
                                    </a>
                                    <div
                                        style={{ marginLeft: 18 }}
                                        onClick={() =>
                                            history.push("/result/" + item.id, {
                                                index: index,
                                            })
                                        }
                                        href={""}
                                    >
                                        <ResultIcon />
                                    </div>
                                </Grid>
                                <Grid item xs={1}>
                                    {item?.status ? (
                                        <TickIcon />
                                    ) : (
                                        <CrossIcon />
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        onClick={() =>
                                            handleEditButtonClick(item)
                                        }
                                    >
                                        <PenIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            handleDeleteButtonClick(item.id)
                                        }
                                    >
                                        <CrossIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <TableEmptyData />
                    )}
                </Grid>
            </div>

            {deleteStatus ? (
                <DeleteModal
                    open={deleteStatus}
                    id={deleteResultId}
                    closeDeleteModal={closeDeleteModal}
                    type="result"
                />
            ) : null}

            {showResults ? (
                <ShowResultsModal
                    data={showResultsData}
                    closeShowModal={closeShowModal}
                />
            ) : null}

            {editStatus ? (
                <EditResultsModal
                    result={editResult}
                    closeEditModal={closeEditModal}
                />
            ) : null}
        </>
    );
};

export default Results;
