import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import Api from "../../API";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CloseChip } from "../../assets/images/SVG/CloseChip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Calculation = () => {
  const history = useHistory();

  const { id } = useParams();

  const [stimulus, setStimulus] = useState([]);

  const [formData, setFormData] = useState({});

  const { calculations } = formData;

  const getCalculationResult = () => {
    toast
      .promise(Api.getData(`calculations/${id}`), {
        pending: "Fetching data, wait...",
        success: "Ready!",
        error: "Something went wrong!",
      })
      .then(({ data }) => {
        setFormData(data);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    Api.getData(`test/stimuluses/${id}/`)
      .then((res) => setStimulus(res.data))
      .catch((e) => console.error(e));

    getCalculationResult();
  }, []);

  const handleChange = (value, index, key) => {
    let updated = calculations;
    updated[index][key] = [...value];

    setFormData({ ...formData, calculations: updated });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    toast
      .promise(
        Api.putData(`calculations/${id}/`, {
          ...formData
        }),
        {
          pending: "Sending your request...",
          error: "Could not send, try again",
          success: "Created successfully",
        }
      )
      .catch((e) => console.error(e));
    history.push("/test/" + id);
  };

  const deleteChip = (index_obj, index_value, key) => {
    let updated = calculations;
    updated[index_obj][key].splice(index_value, 1);

    setFormData({ ...formData, calculations: updated });
  };

  const cancelHandler = () => {
    getCalculationResult();
  };

  return (
    <Grid
      container
      justifyItems="center"
      alignItems="center"
      className="text-dark p-3"
      component="form"
      onSubmit={onSubmitHandler}
    >
      <Grid item xs={12}>
        <Typography variant="h6" className="text-dark text-start py-2">
          {formData.title}
        </Typography>
      </Grid>

      {calculations?.map((item, index) => (
        <div className="row text-start w-100">
          <span className="stimulus-title">{item?.parameter?.title}</span>
          <div className="d-flex row">
            <div className="d-flex flex-row">
              <div className="d-flex flex-column input-row">
                <span className="type-value">Test Value</span>
                <FormControl
                  sx={{maxWidth: 536, width: '100%', minWidth: 250 }}
                >
                  <InputLabel style={{ top: -3 }} id="demo-multiple-chip-label">
                    Groups of Stimuluses
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    style={{ minHeight: 48, maxHeight: 'max-content' }}
                    multiple
                    // required
                    key={index}
                    value={item?.test_stimuli_group}
                    onChange={(e) =>
                      handleChange(e.target.value, index, "test_stimuli_group")
                    }
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Groups of Stimuluses"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, i) => (
                          <Chip
                            className="chip-style"
                            deleteIcon={
                              <div
                                className="chip-icon"
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <CloseChip />
                              </div>
                            }
                            onDelete={() => {
                              deleteChip(index, i, "test_stimuli_group");
                            }}
                            key={i}
                            label={value?.title}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {stimulus.map((obj) => (
                      <MenuItem
                        key={obj.id}
                        value={obj}
                        // style={getStyles(name, personName, theme)}
                      >
                        {obj.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="d-flex flex-column w-auto input-row">
                <span className="type-value">Rest Value</span>
                <FormControl
                  sx={{ maxWidth: 536, width: '100%', minWidth: 250 }}
                >
                  <InputLabel style={{ top: -3 }} id="demo-multiple-chip-label">
                    Groups of Stimuluses
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    // required
        
                    key={index}
                    value={item?.rest_stimuli_group}
                    onChange={(e) =>
                      handleChange(e.target.value, index, "rest_stimuli_group")
                    }
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Groups of Stimuluses"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, i) => (
                          <Chip
                            className="chip-style"
                            deleteIcon={
                              <div
                                className="chip-icon"
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <CloseChip />
                              </div>
                            }
                            onDelete={() => {
                              deleteChip(index, i, "rest_stimuli_group");
                            }}
                            key={i}
                            label={value?.title}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {stimulus.map((obj) => (
                      <MenuItem
                        key={obj.id}
                        value={obj}
                      >
                        {obj.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Grid item container className="mt-5" gap={2}>
        <button className="btn-danger px-4 m-0" onClick={cancelHandler}>
          Cancel
        </button>
        <button type="submit" className="stress__buttons_elem btn-blue">
          Save
        </button>
      </Grid>
    </Grid>
  );
};

export default Calculation;
