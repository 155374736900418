import React from 'react';
import '../DeleteModal/deleteModal.scss'
import {CustomModal} from "../CustomModal/CustomModal";

const LogOutModal = ({open, setLogOutModal}) => {

  const logOut = () => {
    localStorage.clear()
    window.location.reload()
  }
  return (
    <CustomModal
      open={open}
      onClose={() => setLogOutModal(false)}
    >
      <div className="delete__box">
        <p className="delete__title">Are you sure you want to log out?</p>
        <div className="delete__buttons">
          <button className="delete__btn delete-blue" onClick={() => setLogOutModal(false)}>No</button>
          <button className="delete__btn danger" onClick={logOut}>Yes</button>
        </div>
      </div>
    </CustomModal>
  );
};

export default LogOutModal;