import React, {useState, useEffect} from 'react';
import Api from "../../../API";
import '../../../pages/TestInfo/TestInfo.scss'
import {toast} from "react-toastify";
import {FormInput} from "../../FormInput/FormInput";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";


const initialState = {
  category: "",
  title: "",
  description: "",
  duration: "",
}

const UploadStimulusModal = ({modalStatus, setModalStatus, changeModalStatus, title, categories, id, type, testId, editStimulus}) => {

  const [formData, setFormData] = useState({
    category: "",
    title: "",
    description: "",
    duration: "",
    file: ""
  });
  const [file, setFile] = useState(null);
  const userData = JSON.parse(localStorage.getItem('neuroToken'));

  const onCloseHandler = () => {
    setFormData(initialState);
    setFile(null);
    changeModalStatus();
  };

  useEffect(() => {
    setFormData({
      category: editStimulus?.category?.id ? editStimulus?.category?.id : "",
      title: editStimulus?.title ? editStimulus?.title : "",
      description: editStimulus?.description ? editStimulus?.description : "",
      duration: editStimulus?.seconds ? editStimulus?.seconds : "",
      file: editStimulus?.file ? editStimulus?.file : ''});
  }, [editStimulus]);

  const handleChange = ({target}) => {
    setFormData(prev => ({
      ...prev,
      [target.name]: target.value
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    body.append("category", formData.category);
    body.append("title", formData.title);
    body.append("description", formData.description);
    body.append("duration", formData.duration);
    
    if(file) {
      body.append("file", file);
    }
      
    if(!formData.file) {
       body.append("file", file);
    }
   

    editStimulus ?
      toast.promise(Api.putData(`stimulus/update/${testId}/`, body, userData?.access), {
        pending: "Sending...",
        success: "Updated!",
        error: "Could not update"
      })
        .then(() => {
            setModalStatus(false)
        })
        .catch(e => console.error(e))
      :
    toast.promise(Api.postData(`stimulus/create/${id}/`, body, userData?.access), {
      pending: "Sending...",
      success: "Added!",
      error: "Could not add"
    })
      .then(res => {
        if (res.status === 201) {
          setModalStatus(false);
          setFile(null)
          setFormData({ category: "",
          title: "",
          description:  "",
          duration: "",
          file: ''
        })
          
        }
      })
      .catch(e => console.error(e))
  };

  return (
    <div className={`stress__modal${modalStatus ? '-active' : ''} container`}>
      <div className="stress__modal-bg" onClick={onCloseHandler}>
      </div>
      <form className="stress__form" onSubmit={handleSubmit}>

        <h2 className="stress__form_title">{title}</h2>

        <FormInput
          name={"title"}
          value={formData.title}
          onChange={handleChange}
          label={"Title"}
          type={"text"}
          required
        />


        <div className="stress__form_category">
          <p className="stress__form_category_title">Category*</p>
          <RadioGroup
            row
            name="category"
            value={formData.category}
            onChange={handleChange}
            style={{margin: '0 auto', display: 'flex', alignItems: 'center', color: "#625F6E"}}
          >
            {categories.map((obj) => (
              <FormControlLabel value={obj.id} key={obj.id} control={<Radio />} label={obj.title}/>
            ))}
          </RadioGroup>
        </div>

        <div className="stress__form_file">
          <p className="stress__form_category_title">Choose file*</p>
          {(!formData.file) && (!file ? <span className="text-danger">{'File wasn\'t uploaded!'}</span> : <span className="text-dark">{'File downloaded'}</span>)}
          <label
            htmlFor="file"
            className="stress__form_fileInput btn-blue"
          >
            Upload
            <input
              type="file"
              className="stress__form_fileInput"
              id="file"
              name="file"
              required={!formData.file}
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </label>

        </div>

        <div className="stress__inputs">

          <FormInput
            name="duration"
            onChange={handleChange}
            label="Duration"
            type="number"
            value={formData.duration}
            required
          />

          <FormInput
            name="description"
            onChange={handleChange}
            label="Description"
            type="description"
            value={formData.description}
            required
          />
        </div>

        <div className="stress__save">
          <button
            className="stress__save_btn btn-white"
            onClick={onCloseHandler}
          >Cancel
          </button>
          <button
            className="stress__save_btn btn-blue"
          >Save
          </button>
        </div>
      </form>
    </div>

  );
};

export default UploadStimulusModal;