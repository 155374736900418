import React from 'react';
import { useHistory } from "react-router-dom";
import '../DeleteModal/deleteModal.scss'
import Api from "../../../API";
import {CustomModal} from "../CustomModal/CustomModal";

const DeleteTestModal = ({ open, closeDeleteTestModal, id }) => {
  let history = useHistory();
  const userData = JSON.parse(localStorage.getItem('neuroToken'))

  const deleteTest = () => {
    Api.deleteData(`tests/delete/${id}/`, userData?.access)
      .then(() => {
        closeDeleteTestModal();
        history.push("/tests-list");
      })
      .catch(e => console.error(e))
  }

  return (
    <CustomModal
      open={open}
      onClose={closeDeleteTestModal}
    >
      <div className="delete__box">
        <p className="delete__title">Are you sure? Delete?</p>
        <div className="delete__buttons">
          <button className="delete__btn delete-blue" onClick={closeDeleteTestModal}>No</button>
          <button className="delete__btn danger" onClick={deleteTest}>Yes</button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteTestModal;