import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

const data = [
  {
    value: 90,
    gender: "Male"
  },
  {
    value: 50,
    gender: "Female",
  },
  {
    value: 30,
    gender: "They",
  }
]

export const NivoBarChart = () => {
  return (
    <ResponsiveBar
      data={data}
      indexBy="gender"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.25}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'category10' }}
      colorBy="index"
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Gender',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      labelTextColor="#FFFFFF"
      // labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      legends={[
        {
          dataFrom: 'indexes',
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role="application"
      ariaLabel="Gender bar chart"
    />
  );
};