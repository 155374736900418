import React, {useState} from 'react';
import { NavLink} from "react-router-dom";
import {useLocation} from "react-router-dom";
import LogOutModal from "../Modals/LogOutModal";
import logout from './../../assets/images/logout.svg';

import './dashboard.scss'
import Logo from "../../assets/images/logo.svg";
import {TestsIcon} from "../../assets/images/SVG/TestsIcon";
import {Home} from "../../assets/images/SVG/Home";
import {Results} from "../../assets/images/SVG/Results";
import {Grid} from "@mui/material";
import {LogoIcon} from "../../assets/images/SVG/LogoIcon";

const Dashboard = () => {
  const [logOutModal, setLogOutModal] = useState(false);

  const router = useLocation();
  let link = router.pathname;


  return (
    <div className="dashboard overflow-auto">

      <Grid container alignItems="center" justifyContent="left" className="p-3">
        <LogoIcon/>
        <span className="logo-title">Neuro Project</span>
      </Grid>

      <div className="pt-4">
        <NavLink
          exact
          className="dashboard__link"
          to="/home"
          activeClassName="dashboard__link-active"
        >
          <Home color={link === '/home' ? "#fff" : "#ccc"} />
          <p className="dashboard__link-title">Home</p>
        </NavLink>

        <NavLink
          exact
          className="dashboard__link"
          to="/tests-list"
          activeClassName="dashboard__link-active"
        >
          <TestsIcon color={link === '/test' ? "#fff" : "#ccc"} />
          <p className="dashboard__link-title">Tests</p>
        </NavLink>

        <NavLink
          className="dashboard__link"
          to="/results"
          activeClassName="dashboard__link-active"
        >
          <Results color={link === '/results' ? "#fff" : "#ccc"} />
          <p className="dashboard__link-title">Test results</p>
        </NavLink>

        <div
          className="dashboard__quite"
          onClick={() => setLogOutModal(true)}
        >
          <img src={logout} alt="logout"/>
          <p className="dashboard__quite_text">Log out</p>
        </div>
      </div>

      <LogOutModal open={logOutModal} setLogOutModal={setLogOutModal} />
    </div>
  );
};

export default Dashboard;