import React from 'react';

export const ResultIcon = ({color}) => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9333 0.185486C12.8233 0.0695802 12.6724 0 12.516 0H3.15363C1.42603 0 0 1.42035 0 3.14777V20.8521C0 22.5797 1.42603 24 3.15363 24H15.8608C17.5884 24 19.0144 22.5797 19.0144 20.8521V6.79414C19.0144 6.64344 18.9449 6.49861 18.8463 6.38838L12.9333 0.185486ZM13.1014 2.04639L17.0609 6.20289H14.487C13.7218 6.20289 13.1014 5.58839 13.1014 4.82319V2.04639ZM15.8608 22.8406H3.15363C2.06965 22.8406 1.15943 21.9421 1.15943 20.8521V3.14777C1.15943 2.06379 2.06379 1.15943 3.15363 1.15943H11.942V4.82319C11.942 6.23182 13.0783 7.36232 14.487 7.36232H17.855V20.8521C17.855 21.9421 16.9507 22.8406 15.8608 22.8406Z" fill="#006674"/>
    <path d="M8.97884 3.04881C8.8969 3.05468 8.81766 3.08059 8.74814 3.12436C8.67861 3.16813 8.62095 3.22843 8.58023 3.29979L5.92286 7.72875L4.35796 6.03836C4.31488 5.98496 4.26127 5.94097 4.20048 5.90918C4.13968 5.87739 4.07299 5.85838 4.00455 5.85347C3.93612 5.84857 3.86741 5.8579 3.80269 5.88069C3.73798 5.90348 3.67865 5.93929 3.62839 5.986C3.57813 6.0327 3.53802 6.08921 3.51056 6.15208C3.48309 6.21495 3.46886 6.28289 3.46875 6.3515C3.46864 6.42011 3.48264 6.48796 3.5099 6.55092C3.53716 6.61388 3.57708 6.6706 3.62718 6.71747L5.64236 8.89504C5.69499 8.95234 5.76027 8.99658 5.83301 9.02422C5.90574 9.05186 5.98392 9.06209 6.06133 9.0542C6.13874 9.04631 6.21324 9.02052 6.2789 8.97877C6.34457 8.93703 6.39958 8.88047 6.43957 8.81373L9.44388 3.81638C9.49427 3.73768 9.52159 3.64651 9.52275 3.55307C9.52391 3.45962 9.49886 3.36772 9.45044 3.28779C9.40202 3.20786 9.33218 3.14308 9.24882 3.10083C9.16546 3.05858 9.07193 3.0406 8.97884 3.04881Z" fill="#006674"/>
    <path d="M8.97884 9.05036C8.8969 9.05622 8.81766 9.08225 8.74814 9.12602C8.67861 9.16979 8.62095 9.22998 8.58023 9.30133L5.92286 13.7303L4.35796 12.0399C4.31488 11.9865 4.26127 11.9425 4.20048 11.9107C4.13968 11.8789 4.07299 11.86 4.00455 11.8551C3.93612 11.8502 3.86741 11.8594 3.80269 11.8822C3.73798 11.905 3.67865 11.9408 3.62839 11.9875C3.57813 12.0342 3.53802 12.0909 3.51056 12.1537C3.48309 12.2166 3.46886 12.2844 3.46875 12.353C3.46864 12.4217 3.48264 12.4895 3.5099 12.5525C3.53716 12.6154 3.57708 12.6721 3.62718 12.719L5.64236 14.8966C5.69499 14.9539 5.76027 14.9981 5.83301 15.0258C5.90574 15.0534 5.98392 15.0636 6.06133 15.0557C6.13874 15.0479 6.21324 15.0221 6.2789 14.9803C6.34457 14.9386 6.39958 14.882 6.43957 14.8153L9.44388 9.81055C9.49218 9.73195 9.51779 9.64156 9.51789 9.54931C9.51798 9.45706 9.49256 9.36653 9.44442 9.28784C9.39629 9.20914 9.32732 9.14534 9.24515 9.10341C9.16298 9.06148 9.07081 9.04316 8.97884 9.05036ZM8.97884 15.0516C8.8969 15.0575 8.81766 15.0835 8.74814 15.1273C8.67861 15.171 8.62095 15.2312 8.58023 15.3026L5.92286 19.7315L4.35796 18.0411C4.31488 17.9877 4.26127 17.9438 4.20048 17.912C4.13968 17.8802 4.07299 17.8613 4.00455 17.8564C3.93612 17.8515 3.86741 17.8607 3.80269 17.8835C3.73798 17.9063 3.67865 17.9421 3.62839 17.9888C3.57813 18.0355 3.53802 18.0921 3.51056 18.155C3.48309 18.2179 3.46886 18.2857 3.46875 18.3543C3.46864 18.4229 3.48264 18.4907 3.5099 18.5537C3.53716 18.6167 3.57708 18.6734 3.62718 18.7203L5.64236 20.8978C5.69499 20.9551 5.76027 20.9994 5.83301 21.027C5.90574 21.0546 5.98392 21.0649 6.06133 21.057C6.13874 21.0491 6.21324 21.0233 6.2789 20.9816C6.34457 20.9398 6.39958 20.8833 6.43957 20.8165L9.44388 15.8118C9.49218 15.7332 9.51779 15.6428 9.51789 15.5505C9.51798 15.4583 9.49256 15.3678 9.44442 15.2891C9.39629 15.2104 9.32732 15.1466 9.24515 15.1047C9.16298 15.0627 9.07081 15.0444 8.97884 15.0516Z" fill="#006674"/>
    </svg>
    
  );
};