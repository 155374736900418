import axios from "axios";

const baseUrl = "https://australscience.com/api";

const http = axios.create({
    baseURL: baseUrl,
});

const setHeaders = (token) => {
    if (!token) return;
    return {
        headers: {
            Authorization: token ? `JWT ${token}` : "",
        },
    };
};

http.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem("neuroToken"))?.access;
    if (token) {
        config.headers.Authorization = `JWT ${token}`;
    }
    return config;
});

http.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response.status === 401 &&
            error.config &&
            !error.config._isRetry &&
            error.config?.url !== "auth/users/token/" &&
            error.config?.url !== "auth/users/create/"
        ) {
            originalRequest._isRetry = true;
            try {
                const state = JSON.parse(localStorage.getItem("auth"));
                const response = await axios.get(
                    `${baseUrl}/auth/users/token/refresh`,
                    setHeaders(state.data.refresh)
                );
                localStorage.setItem("state", {
                    ...state,
                    authReducer: {
                        ...state.authReducer,
                        data: response.data,
                    },
                });
                return http.request(originalRequest);
            } catch (e) {
                localStorage.removeItem("state");
                //window.location.reload();
            }
        }
        throw error;
    }
);

const Api = {
    getData: (url) => http.get(url),
    postData: (url, body) => http.post(url, body),
    putData: (url, article) => http.put(url, article),
    deleteData: (url, token, options) => http.delete(url, options),
};

export default Api;
