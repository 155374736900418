import React from 'react'
import PieChart from "../../components/Charts/PieChart";
import {CalendarChart} from "../../components/Charts/CalendarChart/CalendarChart";
import {NivoBarChart} from "../../components/Charts/NivoBarChart/NivoBarChart";
import './main.scss';

const ageData = [
  {
    id: "51-70",
    value: 70,
  },
  {
    id: "71 & more",
    value: 24,
  },
  {
    id: "36-50",
    value: 18,
  },
  {
    id: "21-35",
    value: 13,
  },
  {
    id: "11-20",
    value: 10,
  },
  {
    id: "0-10",
    value: 5,
  }
];

const dayData = [
    {
      "value": 95,
      "day": "2021-06-06"
    },
    {
      "value": 178,
      "day": "2021-07-01"
    },
    {
      "value": 105,
      "day": "2021-05-31"
    },
    {
      "value": 70,
      "day": "2021-09-12"
    },
    {
      "value": 145,
      "day": "2021-06-04"
    },
    {
      "value": 6,
      "day": "2015-05-30"
    },
    {
      "value": 302,
      "day": "2021-06-02"
    },
    {
      "value": 97,
      "day": "2021-03-14"
    },
    {
      "value": 299,
      "day": "2021-01-13"
    },
    {
      "value": 250,
      "day": "2021-03-28"
    },
    {
      "value": 227,
      "day": "2021-03-17"
    },
    {
      "value": 4,
      "day": "2021-01-12"
    },
    {
      "value": 349,
      "day": "2021-10-29"
    },
    {
      "value": 20,
      "day": "2021-04-30"
    },
    {
      "value": 33,
      "day": "2015-11-10"
    },
    {
      "value": 215,
      "day": "2021-03-27"
    },
    {
      "value": 208,
      "day": "2015-04-28"
    },
    {
      "value": 163,
      "day": "2021-10-23"
    },
    {
      "value": 179,
      "day": "2021-04-14"
    },
    {
      "value": 89,
      "day": "2021-06-09"
    },
    {
      "value": 99,
      "day": "2021-07-31"
    },
    {
      "value": 74,
      "day": "2021-03-17"
    },
    {
      "value": 218,
      "day": "2021-01-06"
    },
    {
      "value": 390,
      "day": "2021-11-07"
    },
    {
      "value": 381,
      "day": "2015-07-19"
    },
    {
      "value": 172,
      "day": "2021-04-11"
    },
    {
      "value": 376,
      "day": "2021-08-21"
    },
    {
      "value": 206,
      "day": "2021-07-31"
    },
    {
      "value": 275,
      "day": "2021-05-07"
    },
    {
      "value": 63,
      "day": "2021-08-02"
    },
    {
      "value": 63,
      "day": "2020-08-02"
    },
    {
      "value": 100,
      "day": "2020-07-02"
    },
];

function Main() {
  return (
    <div className="main inner-container">
      <div className="main-pieChart">
        <div>
          <PieChart data={ageData} />
        </div>
      </div>
      <div className="main-choropleth text-dark">
        <div style={{height: 500}}>
          <NivoBarChart/>
        </div>
        <div style={{height: 400}}>
          <CalendarChart data={dayData}/>
        </div>
      </div>

    </div>
  );
}

export default Main;