import React from 'react';

export const LogoIcon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_905_21223)">
        <path fillRule="evenodd" clipRule="evenodd" d="M38.0859 0C45.3125 0 50 4.6875 50 11.9141V38.0859C50 45.3125 45.3125 50 38.0859 50H11.9141C4.6875 50 0 45.3125 0 38.0859V11.9141C0 4.6875 4.6875 0 11.9141 0H38.0859Z" fill="#006674"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.208 35.4166C35.7787 35.4166 36.2487 34.9498 36.2487 34.374V27.2925C36.2487 27.2209 36.2415 27.1509 36.2277 27.0833H38.4702C38.9826 27.0833 39.3729 26.6274 39.2864 26.1223C39.0127 24.5238 38.4287 21.3073 37.8582 19.4132C37.7969 19.2095 37.6843 18.8795 37.5635 18.5764C37.5489 18.5398 37.5343 18.5036 37.5197 18.4683C35.5862 13.4243 30.0828 8.33325 24.1654 8.33325C16.8016 8.33325 10.832 14.4893 10.832 22.0833C10.832 26.4536 12.8092 30.3478 15.8915 32.8663V39.5909L28.9117 41.7587V35.4166H35.208Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6243 11.6666C19.6613 11.6666 18.8648 12.3958 18.7608 13.3333H18.2622C16.9208 13.3333 15.8327 14.422 15.8327 15.7652C15.8327 15.9311 15.8493 16.0932 15.8811 16.2499H15.6257C14.5918 16.2499 13.7493 17.0894 13.7493 18.1249C13.7493 18.7459 14.0527 19.2976 14.5196 19.6391C13.5983 19.8563 12.916 20.6816 12.916 21.6666C12.916 22.8148 13.8501 23.7499 15.0025 23.7499H17.0155C16.7978 24.0577 16.666 24.4244 16.666 24.7916C16.666 25.5954 17.3186 26.2499 18.1235 26.2499H20.416V40.3441L22.916 40.7603V24.9999C22.911 24.9324 22.916 24.8635 22.916 24.7933V24.5833C22.916 24.123 23.2891 23.7499 23.7493 23.7499H33.3296C34.485 23.7499 35.416 22.8172 35.416 21.6666C35.416 20.6838 34.7316 19.8571 33.8118 19.6393C34.2787 19.2982 34.5827 18.747 34.5827 18.1249C34.5827 17.1552 33.843 16.3544 32.8956 16.2594C32.9268 16.0998 32.9424 15.9345 32.9407 15.7652C32.9271 14.4248 31.8283 13.3333 30.4865 13.3333H29.9878C29.8838 12.3983 29.0892 11.6666 28.1243 11.6666C27.4819 11.6666 26.9136 11.991 26.5756 12.4853C26.2987 11.5317 25.418 10.8333 24.3743 10.8333C23.3324 10.8333 22.4501 11.5312 22.173 12.4858C21.8353 11.9919 21.2677 11.6666 20.6243 11.6666Z" fill="#0D9463"/>
      <defs>
        <filter id="filter0_i_905_21223" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_905_21223"/>
        </filter>
      </defs>
    </svg>
  );
};