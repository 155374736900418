import React, {useState} from 'react';
import {Redirect, useHistory, Link} from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Api from "../../API";
import './login.scss'
import {FormInput} from "../../components/FormInput/FormInput";
import {Grid} from "@mui/material";

const initialValue = {
  email: "",
  password: "",
  confirm_password: "",
};

const RegisterSchema = Yup.object().shape({
  email: Yup.string().required("This field is required!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("This field is required!"),
  confirm_password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .when("password", {
      is: val => (val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    })
    .required("This field is required!")
});


const Registration = () => {
  let history = useHistory();
  const [serverMessage, setServerMessage] = useState(null);
  const isAuth = localStorage.getItem('neuroToken');

  const onSubmit =(data)=> {
    Api.postData('auth/admins/create/', data)
      .then(res => {
        if (res.status === 201) {
          history.push('/login/')
        }
      })
      .catch(e => {
        console.error(e);
        setServerMessage(e.response.data.detail);
      })
  };

  if(isAuth) {
    return <Redirect to='/home' />
  }

  return (
    <div className="login">
      <div className="login-form text-white px-3">

        <p className="login-form-submit__title">
          Sign up
        </p>

        {serverMessage &&
          <p className="text-warning">
            {serverMessage}
          </p>
        }

        <Formik
          initialValues={initialValue}
          validationSchema={RegisterSchema}
          onSubmit={onSubmit}
        >

          {({values, errors, touched,  handleChange}) => (
            <Form style={{width: "425px"}}>
              <FormInput
                label={<span className="text-white">Email*</span>}
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                color="warning"
                InputProps={{
                  style:{
                    color: "white",
                  }
                }}
                error={errors.email && touched.email}
                helperText={errors.email}
              />
              <FormInput
                label={<span className="text-white">Password*</span>}
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                color="warning"
                InputProps={{
                  style:{
                    color: "white",
                  }
                }}
                error={errors.password && touched.password}
                helperText={errors.password}
              />
              <FormInput
                label={<span className="text-white">Confirm Password*</span>}
                type="password"
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                color="warning"
                InputProps={{
                  style:{
                    color: "white",
                  }
                }}
                error={errors.confirm_password && touched.confirm_password}
                helperText={errors.confirm_password}
              />
              <Grid container alignItems="center" justifyContent="space-between">
                <p className="m-0">Already member?</p>
                <Link to="/login/" className="text-white fw-bold text-decoration-none"> Log in</Link>

                <button
                  type='submit'
                  className="login-form-submit-logIn__btn"
                >
                  Sign up
                </button>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Registration;